
.communityContainer {
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #FFF;
}

.communityImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    display: block;
    object-fit: cover;
}

.communityImageContainer {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
}

.communityImageContainerFull {
    width: 100px;
    height: 100px;
    text-align: center;
}


.communityImageFull {
    width: auto;
    max-width: 100px;
    height: 100px;
    margin: auto;
    display: block;
}

.clickable {
    cursor: pointer;
}

.communitiesContainer {
    padding: 20px;
    height: 100%;
}

