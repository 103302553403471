.rightMenuContainer {
    padding-left: 35px;
}

@media (max-width: 1400px) {
    .rightMenuContainer {
        padding-left: 20px;
    }
}

.markerDesc {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.markerDescText {
    margin-left: 10px;
}