.custom-select-container {
  display: inline-block;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: left;
  position: relative;
  
}



.selected-text {
  background-color: #F5F8FA;
  padding: 6px 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius:10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  box-shadow:inset 0px 0px 0px 1px #ededed;
}

.selected-text.active{
  border-bottom-left-radius:0px;
  border-bottom-right-radius: 0px;
  /*border-top:2px solid rgb(242,101,35);
  border-left:2px solid rgb(242,101,35);
  border-right:2px solid rgb(242,101,35);
  */
}

.selected-text::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 16px;
  border: 7px solid transparent;
  border-color: grey transparent transparent transparent;

}

.selected-text.active::after {
  top: 8px;
  border-color: transparent transparent grey transparent;
}

ul {
  margin: 0;
  padding: 0;
  text-align: left;

}

.select-options {

  width: 100%;
  border-bottom-left-radius:10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  z-index: 2;
    box-shadow: 3px 3px 3px 0px rgba(41, 53, 74, 0.1);

}

.custom-select-option {
  list-style-type: none;
  padding: 6px 20px;
  background: white;
  color:grey;
  font-size:15px;
  cursor:pointer;

border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;

  cursor: pointer;
}



.custom-select-option:last-child{
  border-bottom-left-radius:10px;
  border-bottom-right-radius: 10px;

}

li:hover {
  background-color:#ededed;
  color:#5c5c5c;
  /*border-color: rgb(242,101,35);*/

}
