:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

* {
  scrollbar-color: grey #fff;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  scrollbar-color: grey #fff;
  scrollbar-width: thin;
}

::-moz-scrollbar {
  scrollbar-color: grey #fff;
  scrollbar-width: thin;
}

html {
  margin: 0 auto;
  background: #f7fbfc;
}

body {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
}

h1 {
  margin: 0;
}

.App {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  background: #f7fbfc;
}
a {
  color: #58738c;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animated.delay-3s {
  animation-delay: calc(var(--animate-delay) * 3);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}
