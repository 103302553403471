.emailMessagesContainer {
    margin: 10;
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    height: 60%;
    /* border: 2px solid red; */
}

.emailMessages {
    font-size: 20px;
    width: 90%;
    text-align: center;
}