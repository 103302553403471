.comment-container{
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  width:100%;

}


.item-comment{
  min-height: 50px;
  background-color: #dce3e3;
  flex:1;
  border-radius: 10px;

}
